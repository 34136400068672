<template>
    <v-container fluid>
        <v-form @submit.prevent="saveDepartureAuto">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'departure_auto', params: {id: $route.params.type_receipt}}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col class="py-0" sm="12" cols="12">
                                <v-stepper v-model="e1">
                                    <v-stepper-header >
                                        <v-stepper-step
                                            :complete="e1 > 1"
                                            step="1"
                                        >
                                            {{ $t('step', {number: 1} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 2"
                                            step="2"
                                        >
                                            {{ $t('step', {number: 2} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 3"
                                            step="3">
                                            {{ $t('step', {number: 3} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 4"
                                            step="4">
                                            {{ $t('step', {number: 4} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 5"
                                            step="5">
                                            {{ $t('step', {number: 5} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 6"
                                            step="6">
                                            {{ $t('step', {number: 6} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step
                                            :complete="e1 > 7"
                                            step="7">
                                            {{ $t('step', {number: 7} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step v-if="weight_type_cargo_show"
                                            :complete="e1 > 8"
                                            step="8">
                                            {{ $t('step', {number: 8} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step v-if="!weight_type_cargo_show"
                                            :complete="e1 > 8"
                                            step="8">
                                            {{ $t('step', {number: 8} ) }}
                                        </v-stepper-step>

                                        <v-divider></v-divider>

                                        <v-stepper-step   :complete="e1 > 9" step="9">
                                            {{ $t('step', {number: 9} ) }}
                                        </v-stepper-step>

                                        <v-divider v-if="damages.length > 0"></v-divider>

                                        <v-stepper-step step="10" :complete="e1 > 10" v-if="damages.length > 0">
                                            {{ $t('step', {number: 10} ) }}
                                        </v-stepper-step>
                                    </v-stepper-header>

                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('container_data') }}
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>

                                                            <v-col class="py-0" sm="12" cols="12">
                                                                <ValidationProvider ref="container_number" rules="required|min:2|max:255"
                                                                                    v-slot="{ errors, valid }">
                                                                    <v-text-field v-model="container_number" type="text" :disabled="loading"
                                                                                  :error="!valid" :error-messages="errors"
                                                                                  outlined
                                                                                  :label="$t('container_number')"
                                                                                  color="primary" clearable>
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                            </v-col>


                                                            <v-col class="pt-0" sm="12" cols="12" v-if="check_client===0">
                                                                <v-btn
                                                                    block
                                                                    color="primary"
                                                                    @click="findContainer"
                                                                    :disabled="invalid || loading" :loading="loading"
                                                                >
                                                                    {{ $t('search') }}

                                                                </v-btn>
                                                            </v-col>

                                                            <v-col class="py-0" cols="12" sm="12" v-if="check_client===1">
                                                                <v-card >
                                                                    <v-card-title>
                                                                        {{ $t('photo_of_invoice') }}
                                                                    </v-card-title>
                                                                    <v-card-subtitle>
                                                                        {{ $t('you_need_to_send_photo_invoice_to_manager') }}
                                                                    </v-card-subtitle>
                                                                    <v-card-text>

                                                                        <v-row>
                                                                            <v-col cols="12" class="text-center ">
                                                                                <ValidationProvider ref="photo_no_invoices" name="photo_no_invoices"
                                                                                                    rules="required"
                                                                                                    v-slot="{ errors, valid }">
                                                                                    <media-gallery v-model="photo_no_invoices"/>
                                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                                        {{ errors[0] ? errors[0] : $t('photo_invoices_is_mandatory') }}
                                                                                    </div>

                                                                                </ValidationProvider>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col>
                                                                                <v-btn
                                                                                    block
                                                                                    color="primary"
                                                                                    @click="check_client=0"
                                                                                    :loading="loading"
                                                                                >
                                                                                    {{ $t('back') }}

                                                                                </v-btn>
                                                                            </v-col>
                                                                            <v-col>
                                                                                <v-btn
                                                                                    block
                                                                                    color="primary"
                                                                                    @click="sendTelegram"
                                                                                    :loading="loading"
                                                                                >
                                                                                    {{ $t('send_to_manager') }}

                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-text>

                                                                </v-card>

                                                            </v-col>

                                                            <v-col class="mx-0" cols="12" sm="12" v-if="check_client===2">
                                                                <v-row>

                                                                    <v-col class="py-0" sm="6" cols="12">
                                                                        <ValidationProvider ref="driver_phone" rules="required|min:2|max:255"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-text-field v-model="driver_phone" type="text" :disabled="loading"
                                                                                          :error="!valid" :error-messages="errors"
                                                                                          outlined
                                                                                          :label="$t('driver_phone')"
                                                                                          color="primary" clearable>
                                                                            </v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-col>
                                                                    <v-col class="py-0" sm="6" cols="12">
                                                                        <ValidationProvider ref="driver_name" rules="required|min:2|max:255"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-text-field v-model="driver_name" type="text" :disabled="loading"
                                                                                          :error="!valid" :error-messages="errors"
                                                                                          outlined
                                                                                          :label="$t('driver_name')"
                                                                                          color="primary" clearable>
                                                                            </v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-col>

                                                                    <v-col class="py-0" sm="6" cols="12">
                                                                        <ValidationProvider ref="car_number" rules="required|min:2|max:255"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-text-field v-model="car_number" type="text" :disabled="loading"
                                                                                          :error="!valid" :error-messages="errors"
                                                                                          outlined
                                                                                          :label="$t('car_number')"
                                                                                          color="primary" clearable>
                                                                            </v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-col>
                                                                    <v-col class="py-0" sm="6" cols="12">
                                                                        <ValidationProvider ref="trailer_number" rules="required|min:2|max:255"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-text-field v-model="trailer_number" type="text" :disabled="loading"
                                                                                          :error="!valid" :error-messages="errors"
                                                                                          outlined
                                                                                          :label="$t('trailer_number')"
                                                                                          color="primary" clearable>
                                                                            </v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-col>

                                                                    <v-col class="py-0" sm="6" cols="12">
                                                                        <ValidationProvider ref="container_type" rules="required"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-autocomplete v-model="container_type"
                                                                                            :items="containerTypeItems"
                                                                                            :error="!valid"
                                                                                            :error-messages="errors"
                                                                                            outlined
                                                                                            :search-input.sync="containerTypeSearching"
                                                                                            :no-data-text="containerTypeSearching ? $t('nothing_found_by',{'search': containerTypeSearching}) : $t('nothing_found_container_type')"

                                                                                            :loading="loadingContainerType" :disabled="loading"
                                                                                            :label="$t('container_type')"
                                                                                            @click:clear="clearContainerType"
                                                                                            item-text="name" item-value="uuid"
                                                                                            return-object
                                                                                            autocomplete="off"
                                                                                            color="primary" clearable >
                                                                            </v-autocomplete>
                                                                        </ValidationProvider>
                                                                    </v-col>

                                                                    <v-col class="py-0" cols="12" sm="6">
                                                                        <ValidationProvider ref="seal" rules="required"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-select v-model="seal" :items="sealItems"
                                                                                      @change="selectSeal"
                                                                                      :disabled="loading"
                                                                                      :error="!valid"
                                                                                      :error-messages="errors"
                                                                                      item-text="name" item-value="id"
                                                                                      outlined
                                                                                      :label="$t('is_seal')"></v-select>
                                                                        </ValidationProvider>
                                                                    </v-col>
                                                                    <v-col class="py-0" cols="12" sm="6" v-for="(seal_number, i) in seal_numbers"
                                                                           :key="i">
                                                                        <ValidationProvider :ref="seal_number.name + i" rules="required|min:3|max:255"
                                                                                            v-slot="{ errors, valid }">
                                                                            <v-text-field v-model="seal_number.value" type="text"
                                                                                          outlined
                                                                                          prepend-icon="mdi-delete"
                                                                                          @click:prepend="deleteSealNumber(i)"
                                                                                          append-outer-icon="mdi-plus"
                                                                                          @click:append-outer="addSealNumber"
                                                                                          :error="!valid"
                                                                                          :error-messages="errors"
                                                                                          :disabled="loading"
                                                                                          :items="seal_number"
                                                                                          :item-value="seal_number.value"
                                                                                          :label="$t('seal_number')" color="primary"
                                                                                          clearable>
                                                                            </v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-col>



                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-card-actions v-if="!!client" class="mt-5">
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="primary"
                                                            @click="saveDepartureAuto(2)"
                                                            :disabled="invalid || loading" :loading="loading"
                                                        >
                                                            {{ $t('continue') }}

                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="2">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('container_data') }}
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col class="py-0" cols="12" sm="6">
                                                                <ValidationProvider ref="loaded" rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <v-select v-model="loaded" :items="loadedItems"
                                                                              @change="selectLoaded"
                                                                              :disabled="loading"
                                                                              :error="!valid"
                                                                              :error-messages="errors"
                                                                              item-text="name" item-value="id"
                                                                              outlined
                                                                              :label="$t('loaded')"
                                                                              clearable
                                                                              @click:clear="loaded = null;clearLoaded()"
                                                                    ></v-select>
                                                                </ValidationProvider>
                                                            </v-col>

                                                            <v-col class="py-0" sm="6" cols="12" v-if="weight_type_cargo_show">
                                                                <ValidationProvider ref="cargo_weight" rules="required|min:1|max:255"
                                                                                    v-slot="{ errors, valid }">
                                                                    <v-text-field v-model="cargo_weight" type="number" :disabled="loading"
                                                                                  :error="!valid" :error-messages="errors"
                                                                                  outlined
                                                                                  :label="$t('cargo_weight')"
                                                                                  color="primary" clearable>
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                            </v-col>
                                                            <v-col class="py-0" sm="6" cols="12" v-if="weight_type_cargo_show">
                                                                <ValidationProvider ref="type_cargo" rules="required|min:1|max:255"
                                                                                    v-slot="{ errors, valid }">
                                                                    <v-text-field v-model="type_cargo" type="text" :disabled="loading"
                                                                                  :error="!valid" :error-messages="errors"
                                                                                  outlined
                                                                                  :label="$t('type_cargo')"
                                                                                  color="primary" clearable>
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                            </v-col>

                                                            <v-col class="py-0" cols="12" sm="6">
                                                                <ValidationProvider ref="container_status" rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <v-select v-model="container_status" :items="containerStatusItems"
                                                                              :disabled="loading"
                                                                              :error="!valid"
                                                                              :error-messages="errors"
                                                                              item-text="name" item-value="uuid"
                                                                              outlined
                                                                              :label="$t('container_status')"
                                                                              clearable

                                                                    ></v-select>
                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 1"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="primary"
                                                            @click="saveDepartureAuto(3)"
                                                            :disabled="invalid || loading" :loading="loading"
                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>

                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="3">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_of_attorney') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">

                                                                <ValidationProvider ref="photo_invoices" name="photo_invoices"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_invoices"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_invoices_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 2"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(4)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>

                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="4">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_of_container_in_front') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">

                                                                <ValidationProvider ref="photo_front_views" name="photo_front_views"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_front_views"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_front_views_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 3"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(5)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>

                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="5">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_of_side_view_right') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_side_view_right" name="photo_side_view_right"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_side_view_right"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_side_view_right_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 4"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(6)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="6">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_side_view_left') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_side_view_left" name="photo_side_view_left"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_side_view_left"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_side_view_left_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 5"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(7)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="7">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_rear_view') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_rear_view" name="photo_rear_view"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_rear_view"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_rear_view_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 6"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(8)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="8" v-if="weight_type_cargo_show">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_seal') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_seal" name="photo_seal"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_seal"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_seal_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 7"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(9)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="8" v-if="!weight_type_cargo_show">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_view_inside') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_view_inside" name="photo_view_inside"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_view_inside"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_view_inside_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 7"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(9)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="9">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('damage_data') }}
                                                    </v-card-title>
                                                    <v-card-text>

                                                        <v-card-text v-for="(damage_select, i) in damageItems"
                                                                     :key="i">
                                                            <h2 class="text-h6 mb-2">
                                                                {{ damage_select.name }}
                                                            </h2>

                                                            <v-chip-group
                                                                v-model="damages"
                                                                column
                                                                multiple
                                                            >
                                                                <v-chip v-for="(damage, x) in damage_select.damages"
                                                                        :key="x"
                                                                        filter
                                                                        outlined
                                                                        :value="damage.uuid"
                                                                >
                                                                    {{damage.name}}
                                                                </v-chip>
                                                            </v-chip-group>
                                                        </v-card-text>



                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 8"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn v-if="damages.length > 0"

                                                               color="primary"
                                                               @click="saveDepartureAuto(10)"
                                                               :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('continue') }}
                                                        </v-btn>
                                                        <v-btn v-if="damages.length === 0"

                                                               color="primary"
                                                               @click="completeDepartureRailway()"
                                                               :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('complete') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                        <v-stepper-content step="10" v-if="damages.length > 0">
                                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('photo_damage') }}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        {{ $t('take_required_number_of_photos') }}
                                                    </v-card-subtitle>
                                                    <v-card-text>

                                                        <v-row>
                                                            <v-col cols="12" class="text-center">
                                                                <ValidationProvider ref="photo_damage" name="photo_damage"
                                                                                    rules="required"
                                                                                    v-slot="{ errors, valid }">
                                                                    <media-gallery v-model="photo_damage"/>
                                                                    <div v-show="!valid" class="mt-2 text-center" style="color: red">
                                                                        {{ errors[0] ? errors[0] : $t('photo_damage_is_mandatory') }}
                                                                    </div>

                                                                </ValidationProvider>
                                                            </v-col>
                                                        </v-row>


                                                    </v-card-text>
                                                    <v-card-actions class="mt-5">
                                                        <v-btn text
                                                               @click="e1 = 9"
                                                        >
                                                            {{ $t('step_back') }}
                                                        </v-btn>
                                                        <v-spacer ></v-spacer>
                                                        <v-btn

                                                            color="primary"
                                                            @click="saveDepartureAuto(11)"
                                                            :disabled="invalid || loading" :loading="loading"

                                                        >
                                                            {{ $t('complete') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </ValidationObserver>
                                        </v-stepper-content>

                                    </v-stepper-items>
                                </v-stepper>
                            </v-col>

                        </v-row>
                    </v-card-text>

                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce";
    import MediaGallery from '../components/MediaGallery.vue'

    export default {
        name: 'DepartureAutoForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            MediaGallery
        },
        inject: ['forceRerender'],
        data() {
            return {
                e1: 1,
                step: 1,
                application_access_uuid: null,
                type_receipt_uuid: this.$route.params.type_receipt,
                heading: null,
                progress: 0,
                loading: false,
                id: null,
                author: null,
                type_application: null,
                document_foundation: null,
                exist_translations: {},
                all_translations: true,
                container_number: null,
                driver_phone: null,
                driver_name: null,
                car_number: null,
                trailer_number: null,
                check_client: 0,
                client: null,
                clientItems: [],
                loadingClient: false,
                clientSearching: null,
                photo_no_invoices: [],
                departureSearching: null,
                departure: null,
                departureItems: [],
                loadingDeparture: false,
                destinationSearching: null,
                destination: null,
                destinationItems: [],
                loadingDestination: false,
                platform_number: null,
                containerTypeSearching: null,
                container_type: null,
                containerTypeItems: [],
                loadingContainerType: false,
                seal: null,
                sealItems: [
                    {
                        id: 1,
                        name: this.$t('yes'),
                    },
                    {
                        id: -1,
                        name: this.$t('no'),
                    },
                ],
                seal_numbers: [],
                loaded: null,
                loadedItems: [
                    {
                        id: 1,
                        name: this.$t('yes'),
                    },
                    {
                        id: -1,
                        name: this.$t('no'),
                    },
                ],
                cargo_weight: null,
                type_cargo: null,
                weight_type_cargo_show: false,
                customs_clearance: null,
                customsClearanceItems: [
                    {
                        id: 1,
                        name: this.$t('yes'),
                    },
                    {
                        id: -1,
                        name: this.$t('no'),
                    },
                ],
                container_status: null,
                containerStatusItems: [],
                photo_invoices: [],
                photo_front_views: [],
                photo_side_view_right: [],
                photo_side_view_left: [],
                photo_rear_view: [],
                photo_seal: [],
                photo_view_inside: [],
                photo_damage: [],
                damage: null,
                damageItems: [ ],
                container: [ ],
                damages: [],
                loadingDamage: false,




            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
        },
        watch: {
            clientSearching: debounce(function (val) {
                if (val && !this.client) {
                    this.getClient(val)
                }
            }, 500),
            departureSearching: debounce(function (val) {
                if (val && !this.departure) {
                    this.getDeparture(val)
                }
            }, 500),
            destinationSearching: debounce(function (val) {
                if (val && !this.destination) {
                    this.getDestination(val)
                }
            }, 500),
            containerTypeSearching: debounce(function (val) {
                if (val && !this.container_type) {
                    this.getContainerType(val)
                }
            }, 500),

        },
        async mounted() {
            await this.getDamage()
            await this.getContainerStatus()
            await this.checkCreate()
        },
        methods: {
            async checkCreate() {
                if (this.$route.name === "departure_auto.create") {
                    this.heading = this.$t('departure_auto_creation')
                } else {
                    this.heading = this.$t('departure_auto_editing')
                    if (this.$route.params.id) {
                        await this.getDepartureAuto()
                        await this.setStep()
                    }
                }
            },
            async setStep(){
                this.e1 = this.step;
            },
            async findContainer() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                params.filter = 'search';
                params.container_number = this.container_number;
                if(this.client && this.client.uuid){
                    params.client = this.client.uuid;
                }
                params.type_operation = 'departure';
                params.type_access = 'auto';
                await this.$http
                    .get(`partner/container/${this.container_number}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if(res.body.data && res.body.data.container){

                            this.document_foundation = res.body.data.document_foundation
                            this.author = res.body.data.author.uuid
                            this.type_application = res.body.data.type_application
                            this.application_access_uuid = res.body.data.application_access_uuid
                            if(!this.client && this.clientItems.length === 0){
                                this.client = res.body.data.client
                                this.clientItems = [res.body.data.client]
                            }
                            if(!this.container_type && this.containerTypeItems.length === 0){
                                this.container_type = res.body.data.container_type
                                this.containerTypeItems = [res.body.data.container_type]
                            }


                            this.check_client = 2; //2
                            this.saveDepartureAuto(1);
                        }
                        else{
                            this.check_client = 1; // 1;
                            this.$toastr.error(this.$t('container_not_found'))
                        }

                    })
                    .catch(err => {
                        this.check_client = 1; // 1;
                        this.$toastr.error(this.$t('container_not_found'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getDepartureAuto() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}

                await this.$http
                    .get(`partner/application_departure/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if(res && res.body && res.body.data && res.body.data.container)
                        {
                            this.document_foundation = res.body.data.document_foundation
                            this.author = res.body.data.author
                            this.type_application = res.body.data.type_application
                            this.type_receipt_uuid = res.body.data.type_receipt_uuid
                            this.container_number = res.body.data.container
                            this.car_number = res.body.data.car_number
                            this.trailer_number = res.body.data.trailer_number
                            this.application_access_uuid = res.body.data.application_access_uuid
                            this.driver_phone = res.body.data.driver_phone
                            this.driver_name = res.body.data.driver_name
                            this.loaded = res.body.data.loaded === 1 ? 1 : -1
                            this.weight_type_cargo_show = this.loaded === 1;
                            this.customs_clearance = res.body.data.customs_clearance === 1 ? 1 : -1;
                            this.cargo_weight = res.body.data.cargo_weight
                            this.type_cargo = res.body.data.type_cargo
                            this.container_status = res.body.data.container_status
                            let client = res.body.data.client
                            if (client && client.uuid) {
                                this.check_client = 2;
                                this.client = client
                                this.clientItems = [res.body.data.client]
                            } else {
                                this.clientItems = []
                            }

                            let departure = res.body.data.departure
                            if (departure && departure.uuid) {
                                this.departure = departure
                                this.departureItems = [departure]
                            } else {
                                this.departureItems = []
                            }

                            let destination = res.body.data.destination
                            if (destination && destination.uuid) {
                                this.destination = destination
                                this.destinationItems = [destination]
                            } else {
                                this.destinationItems = []
                            }

                            let container_type = res.body.data.container_type
                            if (container_type && container_type.uuid) {
                                this.container_type = container_type
                                this.containerTypeItems = [res.body.data.container_type]
                            } else {
                                this.containerTypeItems = []
                            }
                            this.platform_number = res.body.data.platform_number
                            this.photo_invoices = res.body.data.photos_1
                            this.photo_front_views = res.body.data.photos_2
                            this.photo_side_view_right = res.body.data.photos_3
                            this.photo_side_view_left = res.body.data.photos_4
                            this.photo_rear_view = res.body.data.photos_5
                            this.photo_seal = res.body.data.photos_6
                            this.photo_view_inside = res.body.data.photos_7
                            this.photo_damage = res.body.data.photos_8


                            this.seal = -1;
                            const seals = res.body.data.seals;
                            let key = 0;
                            seals.forEach((element) => {
                                this.seal_numbers.push(
                                    {
                                        name: 'seal_number' + key,
                                        value: element
                                    });
                                key++;
                                this.seal = 1;
                            });

                            this.damages = res.body.data.damage;
                            this.step = res.body.data.step
                        }
                        else {
                            this.$toastr.error(this.$t('container_data_not_found_in_1c'))
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('container_data_not_found_in_1c'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveDepartureAuto(e1) {
                var _this = this;

                this.progress = 0
                this.loading = true
                var formData = new FormData()



                formData.append('step', this.e1)

                if (this.type_receipt_uuid) {
                    formData.append('type_receipt_uuid', this.type_receipt_uuid)
                }
                if (this.document_foundation) {
                    formData.append('document_foundation', this.document_foundation)
                }

                if (this.application_access_uuid) {
                    formData.append('application_access_uuid', this.application_access_uuid)
                }

                if (this.author) {
                    formData.append('author_uuid', this.author)
                }
                if (this.type_application) {
                    formData.append('type_application', this.type_application)
                }

                if (this.container_number) {
                    formData.append('container_number', this.container_number)
                }

                if (this.driver_phone) {
                    formData.append('driver_phone', this.driver_phone)
                }

                if (this.driver_name) {
                    formData.append('driver_name', this.driver_name)
                }
                if (this.car_number) {
                    formData.append('car_number', this.car_number)
                }

                if (this.trailer_number) {
                    formData.append('trailer_number', this.trailer_number)
                }



                if (this.loaded && this.loaded === 1) {
                    formData.append('loaded', 1)
                }

                if (this.customs_clearance && this.customs_clearance === 1) {
                    formData.append('customs_clearance', 1)
                }

                if (this.cargo_weight) {
                    formData.append('cargo_weight', this.cargo_weight)
                }

                if (this.type_cargo) {
                    formData.append('type_cargo', this.type_cargo)
                }

                if (this.container_status) {
                    formData.append('container_status', this.container_status)
                }

                this.seal_numbers.forEach(function callback(item, key) {
                    formData.append(`seal_numbers[${key}]`, item.value)
                });

                if (this.client && this.client.uuid && this.client.name) {
                    formData.append('client_uuid', this.client.uuid)
                    formData.append('client_name', this.client.name)
                }

                if (this.departure && this.departure.uuid && this.departure.name) {
                    formData.append('departure_uuid', this.departure.uuid)
                    formData.append('departure_name', this.departure.name)
                }

                if (this.destination && this.destination.uuid && this.destination.name) {
                    formData.append('destination_uuid', this.destination.uuid)
                    formData.append('destination_name', this.destination.name)
                }


                if (this.platform_number) {
                    formData.append('platform_number', this.platform_number)
                }

                if (this.container_type && this.container_type.uuid && this.container_type.name) {
                    formData.append('container_type_uuid', this.container_type.uuid)
                    formData.append('container_type_name', this.container_type.name)
                }

                if (this.seal) {
                    if (this.seal.uuid) {
                        formData.append('seal_uuid', this.seal.uuid)
                    } else {
                        formData.append('seal_uuid', this.seal)
                    }
                }


                if (e1 === 4 && this.photo_invoices && this.photo_invoices.length > 0) {
                    for (let i in this.photo_invoices) {
                        if (this.photo_invoices[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_invoices[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_invoices[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_invoices[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_invoices[${i}]`, this.photo_invoices[i])
                        }
                    }
                }
                if (e1 === 5 && this.photo_front_views && this.photo_front_views.length > 0) {
                    for (let i in this.photo_front_views) {
                        if (this.photo_front_views[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_front_views[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_front_views[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_front_views[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_front_views[${i}]`, this.photo_front_views[i])
                        }
                    }
                }
                if (e1 === 6 && this.photo_side_view_right && this.photo_side_view_right.length > 0) {
                    for (let i in this.photo_side_view_right) {
                        if (this.photo_side_view_right[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_side_view_right[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_side_view_right[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_side_view_right[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_side_view_right[${i}]`, this.photo_side_view_right[i])
                        }
                    }
                }
                if (e1 === 7 && this.photo_side_view_left && this.photo_side_view_left.length > 0) {
                    for (let i in this.photo_side_view_left) {
                        if (this.photo_side_view_left[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_side_view_left[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_side_view_left[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_side_view_left[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_side_view_left[${i}]`, this.photo_side_view_left[i])
                        }
                    }
                }
                if (e1 === 8 && this.photo_rear_view && this.photo_rear_view.length > 0) {
                    for (let i in this.photo_rear_view) {
                        if (this.photo_rear_view[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_rear_view[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_rear_view[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_rear_view[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_rear_view[${i}]`, this.photo_rear_view[i])
                        }
                    }
                }
                if (e1 === 9 && this.photo_seal && this.photo_seal.length > 0) {
                    for (let i in this.photo_seal) {
                        if (this.photo_seal[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_seal[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_seal[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_seal[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_seal[${i}]`, this.photo_seal[i])
                        }
                    }
                }
                if (e1 === 9 && this.photo_view_inside && this.photo_view_inside.length > 0) {
                    for (let i in this.photo_view_inside) {
                        if (this.photo_view_inside[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_view_inside[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_view_inside[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_view_inside[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_view_inside[${i}]`, this.photo_view_inside[i])
                        }
                    }
                }

                if (e1 === 11 && this.photo_damage && this.photo_damage.length > 0) {

                    for (let i in this.photo_damage) {
                        if (this.photo_damage[i].length > 250) {
                            const mimeTypeImages = await this.getMimeType(this.photo_damage[i])
                            const blobImages = await this.dataURL64toBlob(this.photo_damage[i])
                            if (mimeTypeImages && blobImages) {
                                formData.append(`photo_damage[${i}]`, blobImages, mimeTypeImages)
                            }
                        } else {
                            formData.append(`photo_damage[${i}]`, this.photo_damage[i])
                        }
                    }
                }


                this.damages.forEach(function callback(item, key) {
                    formData.append(`damage[${key}]`, item)
                });


                if (e1 !== 1) {
                    await this.$http
                        .put(`partner/application_departure/${this.application_access_uuid}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {

                            if (res && res.body && res.body.data && res.body.data.uuid) {
                                if(e1 === 11){

                                    this.completeDepartureRailway();
                                }
                                else{
                                    this.e1 = e1
                                    this.$toastr.success(this.$t('entrance_railway_has_been_updated'))
                                }

                            }

                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('entrance_railway_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            if(e1 !== 11) {
                                this.progress = 0
                                this.loading = false
                            }
                        })
                } else {
                    // Add
                    await this.$http
                        .post('partner/application_departure', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            if (res && res.body && res.body.data && res.body.data.uuid) {
                                this.application_access_uuid = res.body.data.uuid
                                this.$toastr.success(this.$t('entrance_railway_has_been_updated'))
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('entrance_railway_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getClient(str) {
                if (str) {
                    this.loadingClient = true
                    let params = {};
                    params.filter = 'search';
                    params.client = str;
                    await this.$http
                        .get("partner/client", {
                            params: params,
                        })
                        .then(res => {
                            this.clientItems = res.body.data
                        })
                        .catch(err => {
                            // this.check_client = 1;
                            this.clientItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_clients'))
                        })
                        .finally(end => {
                            this.loadingClient = false
                        })
                }
            },
            clearClient() {
                this.check_client = 0;
                if (!this.client) {
                    this.clientItems = []
                }
            },
            async getDeparture(str) {
                if (str) {
                    this.loadingDeparture = true
                    let params = {};
                    params.filter = 'search';
                    params.station = str;
                    await this.$http
                        .get("partner/station", {
                            params: params,
                        })
                        .then(res => {
                            this.departureItems = res.body.data
                        })
                        .catch(err => {
                            this.departureItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_stations'))
                        })
                        .finally(end => {
                            this.loadingDeparture = false
                        })
                }
            },
            clearDeparturet() {
                if (!this.departure) {
                    this.departureItems = []
                }
            },
            async getDestination(str) {
                if (str) {
                    this.loadingDestination = true
                    let params = {};
                    params.filter = 'search';
                    params.station = str;

                    await this.$http
                        .get("partner/station", {
                            params: params,
                        })
                        .then(res => {
                            this.destinationItems = res.body.data
                        })
                        .catch(err => {
                            this.destinationItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_stations'))
                        })
                        .finally(end => {
                            this.loadingDestination = false
                        })
                }
            },
            clearDestination() {
                if (!this.destination) {
                    this.destinationItems = []
                }
            },
            async getContainerType(str) {
                if (str) {
                    this.loadingContainerType = true
                    let params = {};
                    params.filter = 'search';
                    params.container_type = str;
                    await this.$http
                        .get("partner/container_type", {
                            params: params,
                        })
                        .then(res => {
                            this.containerTypeItems = res.body.data
                        })
                        .catch(err => {
                            this.containerTypeItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_container_types'))
                        })
                        .finally(end => {
                            this.loadingContainerType = false
                        })
                }
            },
            clearContainerType() {
                if (!this.container_type) {
                    this.containerTypeItems = []
                }
            },
            async getDamage() {

                this.loadingDamage = true
                let params = {};
                params.filter = 'search';
                await this.$http
                    .get("partner/damage", {
                        params: params,
                    })
                    .then(res => {
                        this.damageItems = res.body.data
                    })
                    .catch(err => {
                        this.damageItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_damages'))
                    })
                    .finally(end => {
                        this.loadingDamage = false
                    })

            },
            addSealNumber(){
                this.seal_numbers.push(
                    {
                        name: 'seal_number' + (this.seal_numbers.length + 1),
                        value: ''
                    })
            },
            deleteSealNumber(i){
                this.seal_numbers.splice(i, 1)
                if(this.seal_numbers.length === 0){
                    this.seal = null;
                }
            },
            selectSeal(){

                if(this.seal === -1){
                    this.seal_numbers = [];
                }
                else if(this.seal === 1 && this.seal_numbers.length === 0){
                    this.seal_numbers.push(
                        {
                            name: 'seal_number' + (this.seal_numbers.length + 1),
                            value: ''
                        })
                }
            },
            selectLoaded(){
                if(this.loaded === 1){
                    this.weight_type_cargo_show = true;
                }
                else if(this.loaded === -1){

                    this.clearLoaded();
                }
            },
            clearLoaded(){
                this.cargo_weight = null;
                this.type_cargo = null;
                this.weight_type_cargo_show = false;

            },
            async getContainerStatus() {

                this.loading = true
                let params = {};
                params.filter = 'search';
                await this.$http
                    .get("partner/container_status", {
                        params: params,
                    })
                    .then(res => {
                        this.containerStatusItems = res.body.data
                    })
                    .catch(err => {
                        this.containerStatusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_container_status'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async completeDepartureRailway(){

                var _this = this;

                this.progress = 0
                this.loading = true
                var formData = new FormData()

                formData.append('application_uuid', this.application_access_uuid)

                await this.$http
                    .post('partner/application_departure/send', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body.status && res.body.status === 'success') {
                            this.$router.push({
                                name: "container_add",
                                params: {
                                    id: _this.$route.params.type_receipt
                                }
                            })
                        }
                        else{
                            this.$router.push({
                                name: "container_error",
                                params: {
                                    id: _this.$route.params.type_receipt
                                }
                            })
                        }

                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('departure_railway_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async sendTelegram(){
                var _this = this;

                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.container_number) {
                    formData.append('container_number', this.container_number)
                }

                for (let i in this.photo_no_invoices) {
                    if (this.photo_no_invoices[i].length > 250) {
                        const mimeTypeImages = await this.getMimeType(this.photo_no_invoices[i])
                        const blobImages = await this.dataURL64toBlob(this.photo_no_invoices[i])
                        if (mimeTypeImages && blobImages) {
                            formData.append(`photo_no_invoices[${i}]`, blobImages, mimeTypeImages)
                        }
                    } else {
                        formData.append(`photo_no_invoices[${i}]`, this.photo_no_invoices[i])
                    }
                }

                await this.$http
                    .post('partner/application/notify_manager', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body.status && res.body.status === 'success') {
                            this.$toastr.success(this.$t('we_have_sent_notification_to_manager'))
                        }



                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('entrance_railway_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }



        }
    }
</script>
